import { AppConfig, Apps, StandaloneApps } from "types";
import { bufferAppKeys } from "./buffer-app-keys";
import { bmhAppKeys } from "./bmh-app-keys";
import { bevacAppKeys } from "./bevac-app-keys";
import { esaSalesAppKeys } from "./esa-sales-app-keys";
import { esaAppKeys } from "./esa-app-keys";
import { esaReusableAppKeys } from "./esa-reusables-app-keys";

export const APP_KEYS: AppConfig = {
  buffer: bufferAppKeys.buffer,
  bufferAccessLink: bufferAppKeys.bufferAccessLink,
  bmh: bmhAppKeys,
  esaReusable: esaReusableAppKeys,
  esaSales: esaSalesAppKeys,
  esa: esaAppKeys,
  bevac: bevacAppKeys,
};

export const APP_DOMAINS: Record<Apps, string> = {
  buffer: process.env.REACT_APP_BUFFER_UI_BAKERY_URL || "",
  bufferAccessLink: process.env.REACT_APP_BUFFER_UI_BAKERY_URL || "",
  bmh: process.env.REACT_APP_BMH_UI_BAKERY_URL || "",
  esa: process.env.REACT_APP_ESA_UI_BAKERY_URL || "",
  esaSales: process.env.REACT_APP_ESA_SALES_UI_BAKERY_URL || "",
  esaReusable: process.env.REACT_APP_ESA_REUSABLE_UI_BAKERY_URL || "",
  bevac: process.env.REACT_APP_BEVAC_UI_BAKERY_URL || "",
};

export const HOST_APPS_DOMAINS: Record<StandaloneApps, string> = {
  buffer: process.env.REACT_APP_BUFFER_CLIENT_PORTAL_URL || "",
  bufferAccessLink:
    process.env.REACT_APP_BUFFER_ACCESS_LINK_CLIENT_PORTAL_URL || "",
  bmh: process.env.REACT_APP_BMH_CLIENT_PORTAL_URL || "",
  bevac: process.env.REACT_APP_BEVAC_CLIENT_PORTAL_URL || "",
  esaSales: process.env.REACT_APP_ESA_SALES_CLIENT_PORTAL_URL || "",
};
