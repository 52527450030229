import React from "react";
import { Container, Typography } from "@mui/material";
import { NotificationImportant as WarningIcon } from "@mui/icons-material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(({ palette: p, spacing: s }) => ({
  wrapper: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    height: "50px",
    width: "50px",
    color: p.warning.main,
  },
  sorry: {
    marginTop: s(1),
    marginBottom: s(1),
    color: p.primary.main,
  },
  content: {
    color: p.primary.main,
  },
}));

export const ErrorDisplay = () => {
  const { classes } = useStyles();
  return (
    <Container className={classes.wrapper}>
      <WarningIcon className={classes.icon} />
      <Typography variant="h1" className={classes.sorry}>
        Sorry!
      </Typography>
      <Typography variant="h2" className={classes.content}>
        Something went wrong.
      </Typography>
    </Container>
  );
};
