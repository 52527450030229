export const esaReusableAppKeys = {
  emailSendersV2: {
    appKey: "8579voexd0",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Email senders widget V2",
    entity: false,
  },
  emailQuarantineManager: {
    appKey: "oE4duXM5t1",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Email and SMS Quarantine Manager",
    entity: false,
  },
  systemsSettingsMessages: {
    appKey: "5nNxlf3E1O",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Systems Settings Messages",
    entity: false,
  },
};
