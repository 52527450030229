import React, { memo } from "react";
import { CircularProgress, Backdrop } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<{ isFixed: boolean }>()(
  ({ zIndex }, { isFixed }) => ({
    backdrop: {
      zIndex: zIndex.modal + 1,
      position: isFixed ? "fixed" : "absolute",
      background: "rgba(255, 255, 255, 0.7)",
    },
  })
);

interface Props {
  open: boolean;
  isFixed?: boolean;
}

const Loader = ({ open, isFixed = false }: Props) => {
  const { classes } = useStyles({ isFixed });

  return (
    <Backdrop className={classes.backdrop} open={open} data-testid="loader">
      <CircularProgress color="primary" />
    </Backdrop>
  );
};

export default memo(Loader);
