export const bufferAppKeys = {
  buffer: {
    scheduleEvent: {
      appKey: "70mUC6sEgk",
      notForProfiles: null,
      onlyForProfiles: null,
      title: "Schedule Calendly meeting for Opportunity widget",
      entity: true,
    },
    privacyPolicy: {
      appKey: "ca7jK3andl",
      notForProfiles: null,
      onlyForProfiles: null,
      title: "Privacy Policy",
      entity: false,
      skipPageLoadData: true,
      skipTwoWayMessaging: true,
    },
    submitQuoteRequest: {
      appKey: "h4CS6KsXJu",
      notForProfiles: null,
      onlyForProfiles: null,
      title: "Submit Quote Request",
      entity: true,
      data: {
        zohoConfig: {
          widget: {
            dimensions: {
              width: 1200,
              height: 1200,
            },
          },
        },
      },
    },
    submitQuoteRequestClient: {
      appKey: "tUS3c54CDS",
      notForProfiles: null,
      onlyForProfiles: null,
      title: "Submit Quote Request",
      entity: true,
    },
    startPolicyRequest: {
      appKey: "aiMHXIdeb8",
      notForProfiles: null,
      onlyForProfiles: null,
      title: "Start Policy Request",
      entity: true,
    },
    startPolicyRequestMobile: {
      appKey: "4QeCarY54w",
      notForProfiles: null,
      onlyForProfiles: null,
      title: "Start Policy Request",
      entity: true,
    },
    createOpportunity: {
      appKey: "2TJ54ccez0",
      notForProfiles: null,
      onlyForProfiles: null,
      title: "Create Opportunity",
      data: {
        zohoConfig: {
          widget: {
            dimensions: {
              width: 1200,
              height: 900,
            },
          },
        },
      },
    },
    newOpportunity: {
      appKey: "Ja5DgYrR1M",
      notForProfiles: null,
      onlyForProfiles: null,
      title: "Update Opportunity",
      data: {
        zohoConfig: {
          widget: {
            dimensions: {
              width: 1200,
              height: 430,
            },
          },
        },
      },
    },
    deactivatePortal: {
      appKey: "QVD8uOpYbm",
      notForProfiles: null,
      onlyForProfiles: null,
      title: "Deactivate Portal",
      entity: false,
      data: {
        zohoConfig: {
          widget: {
            dimensions: {
              width: 835,
              height: 210,
            },
          },
        },
      },
    },
    resendHB2015: {
      appKey: "fzfUBJ3GSe",
      notForProfiles: null,
      onlyForProfiles: null,
      title: "Resend HB2015",
      data: {
        zohoConfig: {
          widget: {
            dimensions: {
              width: 835,
              height: 230,
            },
          },
        },
      },
    },
    benefitterPlan: {
      appKey: "aR8aA2KS3O",
      notForProfiles: null,
      onlyForProfiles: null,
      title: "Benefitter Plans",
      entity: false,
      data: {
        zohoConfig: {
          widget: {
            dimensions: {
              width: 1200,
              height: 430,
            },
          },
        },
      },
    },
    submitQuoteReqToCarriers: {
      appKey: "YjTOKrijbw",
      notForProfiles: null,
      onlyForProfiles: null,
      title: "Submit Quote Carriers",
      entity: false,
      data: {
        zohoConfig: {
          widget: {
            dimensions: {
              width: 1200,
              height: 430,
            },
          },
        },
      },
    },
    confirmInterestBlueprint: {
      appKey: "r78X6PzZ4F",
      notForProfiles: null,
      onlyForProfiles: null,
      title: "Confirm Interest",
      entity: false,
      data: {
        zohoConfig: {
          widget: {
            dimensions: {
              width: 900,
              height: 600,
            },
          },
        },
      },
    },
    markIrrelevant: {
      appKey: "zDUfPGwpP1",
      notForProfiles: null,
      onlyForProfiles: null,
      title: "Mark Irrelevant",
      entity: false,
    },
    signTerminationLetter: {
      appKey: "P3vIS3rvDL",
      notForProfiles: null,
      onlyForProfiles: null,
      title: "Sign Termination Letter",
      entity: true,
    },
    signHB2015Letter: {
      appKey: "yJTTDnrEGy",
      notForProfiles: null,
      onlyForProfiles: null,
      title: "Sign HB2015 Letter",
      entity: true,
    },
    companyInformationForm: {
      appKey: "h5Yh2BqhBB",
      notForProfiles: null,
      onlyForProfiles: null,
      title: "Company Information Form",
      entity: true,
    },
    products: {
      appKey: "Hqzvo7dpwC",
      notForProfiles: null,
      onlyForProfiles: null,
      title: "Products",
      entity: true,
    },
    activePoliciesForm: {
      appKey: "GcRpRWdFfk",
      notForProfiles: null,
      onlyForProfiles: null,
      title: "Active Policies",
      entity: true,
    },
    policyRequirements: {
      entity: true,
      appKey: "QwfLltOZVB",
      notForProfiles: null,
      onlyForProfiles: null,
      title: "Policy Requirements",
    },
    manageQuoteOptions: {
      appKey: "Nn7T8wV5vr",
      title: "Manage Quote Options",
      entity: true,
      data: {
        zohoConfig: {
          widget: {
            dimensions: {
              width: 1200,
              height: 600,
            },
          },
        },
      },
    },
    companyDetails: {
      appKey: "8uHDpE7eQw",
      notForProfiles: null,
      onlyForProfiles: null,
      title: "Company Details",
      entity: true,
    },
    updateCensus: {
      appKey: "U16y7o37hx",
      notForProfiles: null,
      onlyForProfiles: null,
      title: "Update Census",
      entity: true,
    },
    employeeForm: {
      appKey: "rcY9biEOlT",
      notForProfiles: null,
      onlyForProfiles: null,
      title: "Employees",
      entity: true,
    },
    quoteRequestsForm: {
      appKey: "jKZ3qAmLq9",
      notForProfiles: null,
      onlyForProfiles: null,
      title: "Requests",
      entity: false,
    },
    quoteRequestsFormTablet: {
      appKey: "0vthLhaYxT",
      notForProfiles: null,
      onlyForProfiles: null,
      title: "Requests",
      entity: false,
    },
  },
  bufferAccessLink: {
    signTerminationLetter: {
      appKey: "P3vIS3rvDL",
      title: "Sign Termination Letter",
      page: "access-link",
    },
    respondToQuoteRequest: {
      appKey: "ZeQ3YUYpvS",
      title: "Respond to Quote Request",
    },
  },
};
