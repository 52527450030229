import { zohoServices } from "services";
import { ZohoCurrentUser } from "types";

export const checkForUserProfiles = (
  user: ZohoCurrentUser | undefined,
  onlyForProfiles: string[] | null,
  notForProfiles: string[] | null
) => {
  if (!user) return true;
  const { name } = user.profile;
  if (onlyForProfiles) return onlyForProfiles.some((p) => p === name);
  if (notForProfiles) return notForProfiles.every((p) => p !== name);
  return true;
};

export const sendDataToUIBakeryAction = (
  uiBakery: any,
  payload: Record<string, any>
) => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    uiBakery.triggerAction("integrationAppTrigger", payload);
  } catch {
    //
  }
};

/**
 * TODO: Extend these for other actions called from ui bakery
 */

const handleActionsRequestsFromUIB = async (
  actionType: any,
  payload: any,
  zohoPostMessage: (methodsChain: string[], args: unknown[]) => Promise<any>
) => {
  let res;
  switch (actionType) {
    case "CLOSE_ZOHO_POPUP":
      res = zohoServices.closePopup(!!payload?.reload);
      break;

    case "GET_RECORD":
      res = await zohoServices.getRecord({
        Entity: payload?.entity,
        RecordID: payload?.recordID,
      });
      break;

    case "GET_RECORDS":
      res = await zohoServices.getZohoRecords(
        String(payload?.entity),
        payload?.recordIDs ? String(payload?.recordIDs) : undefined
      );
      break;

    case "GET_RELATED_RECORD":
      res = await zohoServices.getRelatedRecords({
        Entity: payload?.entity,
        RecordID: payload?.recordID,
        RelatedList: payload?.relatedList,
      });
      break;

    case "UPDATE_RECORD":
      res = await zohoServices.updateRecord({
        Entity: payload?.entity,
        id: payload?.id,
        data: payload?.data,
      });
      break;

    case "UPDATE_BLUEPRINT":
      res = await zohoServices.updateBlueprint({
        Entity: payload?.entity,
        RecordID: payload?.recordID,
        transitionName: payload?.transitionName,
      });
      break;

    case "GET_USERS":
      res = await zohoServices.getUsers();
      break;

    case "GET_CURRENT_USER":
      res = await zohoServices.getCurrentUser();
      break;

    case "GET_MODULES":
      res = await zohoServices.getModules();
      break;

    case "ZOHO_UTILS":
      res = await zohoPostMessage(
        payload?.methodChain as string[],
        payload?.args as unknown[]
      );
      break;

    default:
      res = undefined;
  }

  return res;
};

export const addZohoActionListeners = (
  uiBakery: any,
  zohoPostMessage: (methodsChain: string[], args: unknown[]) => Promise<any>,
  setLoading: (loading: boolean) => void
) => {
  window.addEventListener("message", (event) => {
    const actionType = event.data?.type;
    const payload = event.data?.data;
    const callbackName = event.data?.callbackName;
    const identifier = event.data?.identifier;

    setLoading(true);
    handleActionsRequestsFromUIB(actionType, payload, zohoPostMessage)
      .then((data) => {
        sendDataToUIBakeryAction(uiBakery, {
          type: actionType,
          status: "success",
          data,
          identifier,
          callbackName,
          payload,
        });
      })
      .catch((err) => {
        const error = err as Error;
        sendDataToUIBakeryAction(uiBakery, {
          type: actionType,
          status: "error",
          err,
          errorMessage: error.message,
          identifier,
          callbackName,
          payload,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  });
};
