export const esaAppKeys = {
  reusableZohoSyncTool: {
    appKey: "akVcmFYRnv",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Zoho Sync Tool",
    entity: false,
  },
  fieldsReplication: {
    appKey: "jDnLpiGxRC",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Fields Replication",
    entity: false,
  },
  senderIdentitiesManagement: {
    appKey: "igKD9gB6Rs",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Sender Identities Management",
    entity: false,
  },
};
