import React, { ReactNode } from "react";
import { ProviderContext, OptionsObject, SharedProps } from "notistack";

import NotificationDisplay from "./NotificationDisplay";

export default (() => {
  let noti: ProviderContext | null = null;
  let commonOptions: OptionsObject | null = null;

  const display = ({
    message,
    variant,
    options,
  }: {
    message: ReactNode;
    variant?: SharedProps["variant"];
    options?: OptionsObject;
  }) => {
    noti?.enqueueSnackbar(message, {
      content: (key) => (
        <NotificationDisplay
          variant={variant}
          message={message}
          messageKey={key}
          notiRef={noti}
        />
      ),
      ...(commonOptions || {}),
      ...(options || {}),
    });
  };

  return {
    setRef: (ref: ProviderContext | null): void => {
      noti = ref;
    },
    setCommonOptions: (opts: OptionsObject) => {
      commonOptions = opts;
    },
    getRef: (): ProviderContext | null => noti,
    notify: (message: ReactNode, options?: OptionsObject): void => {
      display({ message, options });
    },
    notifyError: (message: ReactNode, options: OptionsObject = {}): void => {
      display({ message, options, variant: "error" });
    },
    notifySuccess: (message: ReactNode, options: OptionsObject = {}): void => {
      display({ message, options, variant: "success" });
    },
    notifyInfo: (message: ReactNode, options: OptionsObject = {}): void => {
      display({ message, options, variant: "info" });
    },
    notifyWarning: (message: ReactNode, options: OptionsObject = {}): void => {
      display({ message, options, variant: "warning" });
    },
  };
})();
