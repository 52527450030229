import { HostPortalHandlers } from "./types";

interface SendParams {
  message: string;
  data: Record<string, unknown>;
  appKey: string;
}

export const sendDataToHostPortalAction = (
  payload: SendParams,
  origin: string
) => {
  try {
    if (window.top) {
      window.top.postMessage(JSON.stringify(payload), origin);
    }
  } catch {
    //
  }
};

export const sendDataToUIBakeryAction = (
  uiBakery: any,
  payload: Record<string, any>
) => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    uiBakery.triggerAction("integrationAppTrigger", payload);
  } catch {
    //
  }
};

export const addHostAppActionListeners = (
  handlers: HostPortalHandlers[],
  origin: string,
  appKey: string
) => {
  window.addEventListener("message", (event) => {
    try {
      if (event.origin === origin) {
        const data = JSON.parse(event.data as string) as SendParams;
        if (data.appKey === appKey) {
          handlers.forEach(({ message, handler }) => {
            if (data.message === message) {
              handler(data.data);
            }
          });
        }
      }
    } catch {
      //
    }
  });
};

export const addUIBakeryActionListeners = (
  uiBakery: any,
  origin: string,
  zohoPostMessage: (methodsChain: string[], args: unknown[]) => Promise<any>,
  appKey: string
) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  uiBakery.onMessage("customEvent", (data: Record<string, unknown>) => {
    try {
      if (data.type === "ZOHO_ACTION") {
        const payload = data.data as Record<string, any>;
        zohoPostMessage(
          payload?.methodChain as string[],
          payload?.args as unknown[]
        )
          .then((res) => {
            sendDataToUIBakeryAction(uiBakery, {
              type: "ZOHO_ACTION",
              status: "success",
              res,
            });
          })
          .catch((err) => {
            sendDataToUIBakeryAction(uiBakery, {
              type: "ZOHO_ACTION",
              status: "error",
              err,
            });
          });
      } else {
        sendDataToHostPortalAction(
          {
            message: "ACTION_FROM_UI_BAKERY",
            data,
            appKey,
          },
          origin
        );
      }
    } catch {
      //
    }
  });
};
