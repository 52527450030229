import { configureStore, combineReducers } from "@reduxjs/toolkit";
// import { AxiosError } from "axios";
// import { apiClient, silentClient } from "apis";
// import { notifications } from "services";
// import auth, { authActions } from "./auth";
import auth from "./auth";
import zoho from "./zoho";
import { AppState } from "./types";

const initialState: AppState = {
  zoho: {
    entity: "",
    ids: [],
    currentUser: null,
    records: {},
  },
  auth: {
    token: null,
    error: null,
    loading: true,
  },
};

export const createStore = () => {
  const reducer = combineReducers({
    auth,
    zoho,
  });

  return configureStore({
    reducer,
    preloadedState: initialState || {},
    middleware: (getDefaultMiddleware) => {
      const middlewares = [
        ...getDefaultMiddleware({
          serializableCheck: false,
        }),
      ];
      return middlewares;
    },
  });
};

const store = createStore();

// // Add a response interceptor
// apiClient.interceptors.response.use(
//   (response) => {
//     // Any status code that lie within the range of 2xx cause this function to trigger
//     // Do something with response data
//     return response;
//   },
//   async (error: AxiosError<{ message: string }>) => {
//     // Any status codes that falls outside the range of 2xx cause this function to trigger
//     // Do something with response error
//     const originalRequest = error.config as typeof error.config & {
//       retryReq: boolean;
//     };

//     if (error.response?.status === 401 && !originalRequest.retryReq) {
//       originalRequest.retryReq = true;
//       const res = await store.dispatch(authActions.refreshToken());
//       if (authActions.refreshToken.fulfilled.match(res)) {
//         const token = res.payload;
//         if (token) {
//           originalRequest.headers = {
//             ...originalRequest.headers,
//             Authorization: `Bearer ${token}`,
//           };
//           return apiClient(originalRequest);
//         }
//         return Promise.reject(error);
//       }
//     }

//     const message =
//       error?.response?.data?.message || error?.message || "Unknown error";

//     notifications.notifyError(message);
//     return Promise.reject(error);
//   }
// );

// // Add a response interceptor
// silentClient.interceptors.response.use(
//   (response) => {
//     // Any status code that lie within the range of 2xx cause this function to trigger
//     // Do something with response data
//     return response;
//   },
//   async (error: AxiosError) => {
//     const originalRequest = error.config as typeof error.config & {
//       retryReq: boolean;
//     };

//     if (error.response?.status === 401 && !originalRequest.retryReq) {
//       originalRequest.retryReq = true;
//       const res = await store.dispatch(authActions.refreshToken());
//       if (authActions.refreshToken.fulfilled.match(res)) {
//         const token = res.payload;
//         if (token) {
//           originalRequest.headers = {
//             ...originalRequest.headers,
//             Authorization: `Bearer ${token}`,
//           };
//           return silentClient(originalRequest);
//         }
//         return Promise.reject(error);
//       }
//     }
//     // Any status codes that falls outside the range of 2xx cause this function to trigger
//     // Do something with response error
//     return Promise.reject(error);
//   }
// );

export default store;
