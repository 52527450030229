import { APP_DOMAINS, APP_KEYS } from "configs";
import { baseURLs } from "helpers";
import { Apps, AppProperties } from "types";

enum Environment {
  STAGING = "staging",
  Dev = "dev",
}

type AppConfigType = {
  path: Apps;
  fallbackApp?: string | null;
  appKey: string;
  environment?: string | null;
};

export const getAppConfig = ({
  path,
  fallbackApp,
  appKey,
  environment,
}: AppConfigType): AppProperties & {
  url: string;
  baseURL?: string;
  n8nUrl?: string;
  crhUrl?: string;
  calUrl?: string;
  appKeyName: string;
} => {
  let value;

  if (APP_KEYS[path]) {
    value = APP_KEYS[path][appKey];
  }
  const fallback = fallbackApp as Apps;

  if (!value) {
    throw new Error("Cannot find app config");
  }
  const appBaseURLs = fallback ? baseURLs[fallback] : baseURLs[path];
  if (!appBaseURLs) {
    throw new Error("Cannot find app base urls");
  }

  const url = `${APP_DOMAINS[path]}${
    environment && Object.values(environment as Environment)
      ? `/${environment}`
      : ""
  }/share/${value.appKey}/${value.page || ""}`;

  return {
    ...value,
    url,
    baseURL: appBaseURLs.backend,
    n8nUrl: appBaseURLs.n8n,
    crhUrl: appBaseURLs.crh,
    calUrl: appBaseURLs.cal,
    appKeyName: appKey,
  };
};
