import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "redux/types";
import { authActions, authSelectors } from "redux/auth";
import { getENText } from "helpers";
import {
  zohoActions,
  transformInitZohoPayload,
  zohoSelectors,
} from "redux/zoho";

import { AppProperties, Apps, StandaloneApps, RouteServiceEnum } from "types";
import Loader from "components/Loader";
import { ErrorMessage } from "components/ErrorMessage";
import { IframeComponent } from "components/IframeComponent";
import { InfoMessage } from "components/InfoMessage";
import Zoho from "services/zoho";
import { getZohoPostMessage } from "services/zoho-utils";
import { PortalEnum } from "@deep-consulting-solutions/zoho-utils-fe";
import TechnicalConfigRestrictionInfo from "components/TechnicalConfigRestrictionInfo";
import axios from "axios";
import { keys } from "helpers/keys";
import { checkForUserProfiles, addZohoActionListeners } from "./helpers";

interface Props extends AppProperties {
  init: boolean;
  url: string;
  path: Apps;
  extraData: string | null;
  baseURL?: string;
  n8nUrl?: string;
  crhUrl?: string;
  calUrl?: string;
  technicalConfigRestriction?: any;
  fallbackApp?: StandaloneApps | null;
}

export const ZohoIntegration = ({
  init,
  notForProfiles,
  onlyForProfiles,
  url,
  title,
  onlyForTabs,
  services,
  entity,
  path,
  baseURL,
  extraData,
  n8nUrl,
  crhUrl,
  calUrl,
  technicalConfigRestriction = false,
  fallbackApp,
  ...rest
}: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const [isIframeLoaded, setIsIframeLoaded] = useState(false);
  const [loadingCallCount, setLoadingCallCount] = useState<number>(0);
  const [riskAcknowledged, setRiskAcknowledged] = useState(false);
  const [isAdminUsersLoading, setAdminUsersLoading] = useState<boolean>(false);
  const [widgetAccess, setWidgetAccess] = useState(false);

  const iframeID = useMemo(() => "some-ui-bakery-component", []);
  const uiBakery = useMemo(
    () =>
      window.UIBakery && isIframeLoaded
        ? window.UIBakery(`#${iframeID}`)
        : undefined,
    [iframeID, isIframeLoaded]
  );

  const handleIframeLoaded = useCallback(() => {
    setIsIframeLoaded(true);
  }, []);

  const handleRiskAcknowledged = useCallback(() => {
    setRiskAcknowledged(true);
  }, []);

  const zohoIds = useSelector(zohoSelectors.getIds);
  const entityRecord = useSelector(zohoSelectors.getEntity);
  const getCurrentUser = useSelector(zohoSelectors.getCurrentUser);
  const records = useSelector(zohoSelectors.getRecords);
  const token = useSelector(authSelectors.token);
  const record = records[zohoIds[0]];

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [infoMessage, setInfoMessage] = useState("");
  const [uiBakeryReady, setUIBakeryReady] = useState(false);
  const [isResized, setIsResized] = useState(false);

  const isActionRequestLoading = useMemo(
    () => loadingCallCount > 0,
    [loadingCallCount]
  );

  const setActionRequestLoading = useCallback((param: boolean) => {
    setLoadingCallCount((current) => (param ? current + 1 : current - 1));
  }, []);

  const zohoClientPostMessage = useMemo(() => {
    return getZohoPostMessage(path, {
      portal: PortalEnum.CRM,
      token: `Bearer ${token}`,
    });
  }, [path, token]);

  const handleUIBakeryReady = useCallback(() => {
    if (uiBakery) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      uiBakery.onReady(() => {
        setUIBakeryReady(true);
      });
    }
  }, [uiBakery]);

  const pageLoadData = useMemo(
    () => ({
      ids: zohoIds,
      id: zohoIds[0],
      entityRecord,
      records,
      record,
      token,
      baseURL,
      extraData: extraData ? JSON.parse(extraData) : undefined,
      n8nUrl,
      crhUrl,
      calUrl,
    }),
    [
      record,
      records,
      token,
      baseURL,
      entityRecord,
      zohoIds,
      extraData,
      n8nUrl,
      crhUrl,
      calUrl,
    ]
  );

  const returnWithError = useCallback(
    (errorMessage = "Something went wrong") => {
      setError((current: any) => current || errorMessage);
      return false;
    },
    []
  );

  const returnWithInfoMessage = useCallback(
    (message = "Something went wrong") => {
      setInfoMessage((current: any) => current || message);
      return false;
    },
    []
  );

  const initZoho = useCallback(async () => {
    try {
      const res = await dispatch(zohoActions.initZoho());

      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      if (!zohoActions.initZoho.fulfilled.match(res)) {
        return returnWithError();
      }

      const entityError = "Cannot find zoho entity";

      if (onlyForTabs) {
        const { tabs, errorMessage } = onlyForTabs;
        if (!res.payload) {
          return returnWithError(entityError);
        }

        const tabArray = Array.isArray(tabs) ? tabs : [tabs];

        if (!tabArray.includes(res.payload.Entity)) {
          const message =
            typeof errorMessage === "function"
              ? errorMessage(res.payload.Entity)
              : errorMessage || getENText("default.notAllowed.widget");
          return returnWithInfoMessage(message);
        }
      }

      if (entity) {
        if (!res.payload) {
          return returnWithError(entityError);
        }
        const { ids } = transformInitZohoPayload(res.payload);
        if (!ids || !ids.length) {
          return returnWithError(entityError);
        }
        return ids;
      }
      return true;
    } catch {
      returnWithError();
      setLoading(false);
      return false;
    }
  }, [entity, dispatch, returnWithError, onlyForTabs, returnWithInfoMessage]);

  const getToken = useCallback(async () => {
    const res = await dispatch(authActions.getToken(path));
    if (!authActions.getToken.fulfilled.match(res)) {
      return returnWithError("Cannot get Zoho token");
    }
    return true;
  }, [returnWithError, dispatch, path]);

  const getAdminUsers = useCallback(async () => {
    if (technicalConfigRestriction && token && crhUrl && getCurrentUser?.id) {
      setAdminUsersLoading(true);
      return axios
        .post(
          `${crhUrl}/request/crm`,
          {
            url: `/api/v1/db/data/noco/${
              keys[fallbackApp || path].technicalAdminUsersKey
            }/TechnicalAdminUsers/views/TechnicalAdminUsers`,
            app: "NOCODB",
            method: "GET",
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          if (response.data) {
            const result = response.data.data.list[0].userlDs as string[];
            setWidgetAccess(result.includes(getCurrentUser?.id));
            setAdminUsersLoading(false);
          }
        });
    }
    return false;
  }, [
    crhUrl,
    token,
    path,
    technicalConfigRestriction,
    fallbackApp,
    getCurrentUser,
  ]);

  const fetchCurrentUser = useCallback(async () => {
    const res = await dispatch(zohoActions.fetchCurrentUser());
    if (!zohoActions.fetchCurrentUser.fulfilled.match(res)) {
      return returnWithError("Cannot get Zoho Current User.");
    }
    if (
      (notForProfiles || onlyForProfiles) &&
      !checkForUserProfiles(
        res.payload,
        onlyForProfiles || null,
        notForProfiles || null
      )
    ) {
      return returnWithInfoMessage(
        "Sorry! You do not have access to this feature!"
      );
    }
    return true;
  }, [
    returnWithError,
    dispatch,
    returnWithInfoMessage,
    notForProfiles,
    onlyForProfiles,
  ]);

  const fetchRecords = useCallback(async () => {
    const res = await dispatch(zohoActions.fetchRecords());
    if (!zohoActions.fetchRecords.fulfilled.match(res)) {
      return returnWithError("Cannot get Zoho Records");
    }
    return true;
  }, [returnWithError, dispatch]);

  const runServices = useCallback(async () => {
    setLoading(true);
    try {
      const tasks: Promise<boolean>[] = [];

      if (init) {
        const inited = await initZoho();

        if (!inited) {
          setLoading(false);
          return;
        }
        tasks.push(getToken());
      }

      let serviceArray: RouteServiceEnum[] = [];
      if (services) {
        serviceArray = Array.isArray(services) ? services : [services];
      }

      const needUserProfileData = !!(
        notForProfiles ||
        onlyForProfiles ||
        technicalConfigRestriction
      );
      if (needUserProfileData) tasks.push(fetchCurrentUser());
      serviceArray.forEach((service) => {
        if (!needUserProfileData || service === RouteServiceEnum.currentUser) {
          tasks.push(fetchCurrentUser());
        }
        if (service === RouteServiceEnum.records) {
          tasks.push(fetchRecords());
        }
      });

      await Promise.all(tasks);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }, [
    init,
    services,
    initZoho,
    getToken,
    fetchCurrentUser,
    fetchRecords,
    notForProfiles,
    onlyForProfiles,
    technicalConfigRestriction,
  ]);

  const handleZohoActionsListeners = useCallback(() => {
    if (!loading && url && pageLoadData && uiBakeryReady) {
      addZohoActionListeners(
        uiBakery,
        zohoClientPostMessage,
        setActionRequestLoading
      );
    }
  }, [
    loading,
    url,
    pageLoadData,
    uiBakery,
    uiBakeryReady,
    zohoClientPostMessage,
    setActionRequestLoading,
  ]);

  /**
   * Configures widget dimension if values are passed
   */
  const setWidgetDimension = useCallback(() => {
    if (!isResized) {
      const dimensions = rest?.data?.zohoConfig?.widget?.dimensions as {
        height: number;
        width: number;
      };
      if (dimensions) {
        Zoho.resizeWidget(dimensions);
      }
    }
    setIsResized(true);
  }, [rest?.data?.zohoConfig?.widget?.dimensions, isResized]);

  useEffect(() => {
    if (!loading && !infoMessage && !error) {
      setWidgetDimension();
    }
  }, [setWidgetDimension, loading, infoMessage, error]);

  useEffect(() => {
    runServices();
  }, [runServices]);

  useEffect(() => {
    handleZohoActionsListeners();
  }, [handleZohoActionsListeners]);

  useEffect(() => {
    handleUIBakeryReady();
  }, [handleUIBakeryReady]);

  useEffect(() => {
    getAdminUsers();
  }, [getAdminUsers]);

  if (loading) {
    return <Loader open />;
  }

  if (infoMessage) {
    return <InfoMessage message={infoMessage} title={title} />;
  }

  if (error) {
    return <ErrorMessage message={error} title={title} />;
  }

  if (technicalConfigRestriction && !riskAcknowledged) {
    return (
      <>
        {isAdminUsersLoading ? (
          <Loader open />
        ) : (
          <TechnicalConfigRestrictionInfo
            handleRiskAcknowledged={handleRiskAcknowledged}
            access={widgetAccess}
          />
        )}
      </>
    );
  }

  return (
    <>
      <Loader open={isActionRequestLoading} />
      <IframeComponent
        url={url}
        title={title}
        pageLoadData={pageLoadData}
        handleIframeLoaded={handleIframeLoaded}
        iframeID={iframeID}
      />
    </>
  );
};
