import React, { useState, useMemo, useEffect, useCallback } from "react";

import Loader from "components/Loader";
import { IframeComponent } from "components/IframeComponent";
import { HOST_APPS_DOMAINS } from "configs";
import { AppProperties, Apps, StandaloneApps } from "types";
import { getZohoPostMessage } from "services/zoho-utils";
import { PortalEnum } from "@deep-consulting-solutions/zoho-utils-fe";
import { ErrorMessage } from "components/ErrorMessage";
import {
  addUIBakeryActionListeners,
  sendDataToHostPortalAction,
  addHostAppActionListeners,
  sendDataToUIBakeryAction,
} from "./helpers";

interface Props extends AppProperties {
  url: string;
  title: string;
  path: Apps;
  extraData: string | null;
  baseURL?: string;
  crhUrl?: string;
  calUrl?: string;
  fallbackApp?: StandaloneApps | null;
  appKeyName: string;
}

declare global {
  interface Window {
    UIBakery?: (instance: string) => any;
  }
}

function convertToLowerCaseAndHyphenate(inputString: string) {
  // Convert to lowercase and replace spaces with hyphens
  return inputString.toLowerCase().replace(/\s+/g, "-");
}

export const ClientPortalIntegration = ({
  url,
  title,
  path,
  skipPageLoadData,
  extraData,
  baseURL,
  crhUrl,
  calUrl,
  fallbackApp,
  appKeyName: appKey,
}: Props) => {
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState("");
  const [recordID, setRecordID] = useState("");
  const [uiBakeryReady, setUIBakeryReady] = useState(false);
  const [isIframeLoaded, setIsIframeLoaded] = useState(false);
  const [error, setError] = useState("");

  const iframeID = useMemo(
    () => convertToLowerCaseAndHyphenate(title) || "some-ui-bakery-component",
    [title]
  );

  const uiBakery = useMemo(
    () =>
      window.UIBakery && isIframeLoaded
        ? window.UIBakery(`#${iframeID}`)
        : undefined,
    [isIframeLoaded, iframeID]
  );

  const handleIframeLoaded = useCallback(() => {
    setIsIframeLoaded(true);
  }, []);

  const zohoPostMessage = useMemo(
    () =>
      getZohoPostMessage(path, {
        token,
        portal: PortalEnum.CLIENT_PORTAL,
      }),
    [path, token]
  );

  const pageLoadData = useMemo(() => {
    return {
      id: recordID,
      token,
      extraData: extraData ? JSON.parse(extraData) : undefined,
      baseURL,
      crhUrl,
      calUrl,
    };
  }, [token, recordID, extraData, baseURL, crhUrl, calUrl]);

  const hostAppDomainPath = useMemo(() => {
    if (path === "esa" || path === "esaReusable") {
      return fallbackApp;
    }
    return path;
  }, [path, fallbackApp]);

  const hostAppDomain = useMemo(() => {
    if (!hostAppDomainPath) {
      setError("Cannot find host app domain");
      return "";
    }
    return HOST_APPS_DOMAINS[hostAppDomainPath];
  }, [hostAppDomainPath]);

  const handleAddHostAppEventListenerForIntegrationApp = useCallback(() => {
    addHostAppActionListeners(
      [
        {
          message: "PAGE_LOAD_DATA",
          handler: (payload) => {
            if (payload) {
              setToken(payload.token as string);
              setRecordID(payload.recordID as string);
            }
          },
        },
      ],
      hostAppDomain,
      appKey
    );
  }, [hostAppDomain, appKey]);

  const handleAddHostAppEventListener = useCallback(() => {
    if (uiBakery) {
      addHostAppActionListeners(
        [
          {
            message: "UI_BAKERY_TRIGGER",
            handler: (payload) => {
              if (payload) {
                sendDataToUIBakeryAction(uiBakery, payload);
              }
            },
          },
        ],
        hostAppDomain,
        appKey
      );
    }
  }, [hostAppDomain, uiBakery, appKey]);

  const handleUIBakeryActionsListeners = useCallback(() => {
    if (uiBakeryReady) {
      addUIBakeryActionListeners(
        uiBakery,
        hostAppDomain,
        zohoPostMessage,
        appKey
      );
    }
  }, [hostAppDomain, uiBakeryReady, uiBakery, zohoPostMessage, appKey]);

  const handleRequestDetailsFromHostApp = useCallback(() => {
    sendDataToHostPortalAction(
      {
        message: "INTEGRATION_APP_READY",
        data: {},
        appKey,
      },
      hostAppDomain
    );
  }, [hostAppDomain, appKey]);

  const handleInformHostAppUibIsReady = useCallback(() => {
    if (uiBakeryReady) {
      sendDataToHostPortalAction(
        {
          message: "UIB_READY",
          data: {},
          appKey,
        },
        hostAppDomain
      );
    }
  }, [uiBakeryReady, hostAppDomain, appKey]);

  const handleReady = useCallback(() => {
    if (skipPageLoadData || token) {
      setLoading(false);
    }
  }, [token, skipPageLoadData]);

  const handleUIBakeryReady = useCallback(() => {
    if (uiBakery) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      uiBakery.onReady(() => {
        setUIBakeryReady(true);
      });
    }
  }, [uiBakery]);

  useEffect(() => {
    handleAddHostAppEventListenerForIntegrationApp();
  }, [handleAddHostAppEventListenerForIntegrationApp]);

  useEffect(() => {
    handleAddHostAppEventListener();
  }, [handleAddHostAppEventListener]);

  useEffect(() => {
    handleUIBakeryActionsListeners();
  }, [handleUIBakeryActionsListeners]);

  useEffect(() => {
    handleRequestDetailsFromHostApp();
  }, [handleRequestDetailsFromHostApp]);

  useEffect(() => {
    handleReady();
  }, [handleReady]);

  useEffect(() => {
    handleUIBakeryReady();
  }, [handleUIBakeryReady]);

  useEffect(() => {
    handleInformHostAppUibIsReady();
  }, [handleInformHostAppUibIsReady]);

  if (error) {
    return <ErrorMessage message={error} title={title} />;
  }

  if (loading) {
    return <Loader open />;
  }

  return (
    <IframeComponent
      url={url}
      title={title}
      pageLoadData={pageLoadData}
      skipPageLoadData={skipPageLoadData}
      handleIframeLoaded={handleIframeLoaded}
      iframeID={iframeID}
    />
  );
};
