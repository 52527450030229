export enum ZohoServiceEnum {
  currentUser = "currentUser",
}

export interface ZohoButtonEntity {
  ButtonPosition: string;
  Entity: string;
  EntityId: string | string[];
}

export type ZohoRecord = Record<string, any>;

export type ZohoEntity = ZohoButtonEntity;

export interface Layout {
  name: string;
  id: string;
}

export interface ZohoAccount {
  k: string;
}

export interface Client {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  mcm?: {
    id: string;
    name: string;
    zohoID: string;
  };
}

export interface Clients {
  [id: string]: Client;
}

export interface ObjWithId {
  id: string;
}

export interface ObjWithAny {
  [key: string]: any;
}

export interface Data extends ObjWithId, ObjWithAny {}

export interface Column<T extends Data> {
  key: keyof Omit<T, "id">;
  header: string;
  info: string;
  editable: boolean;
  required: boolean;
  isBoolean?: boolean;
  align?: "right" | "center" | "left";
  autoFocus?: boolean;
  stickyLeft?: boolean;
}

export enum RowActionsEnum {
  Edit = "Edit",
  ChangeStatus = "Change Status",
}

export enum StatusEnum {
  Active = "Active",
  Inactive = "Inactive",
}

export interface PatchTestObxCodesReqBody {
  bmhName: string;
  labCodes: { labKey: string; code: string }[];
}

export interface ZohoClientRecord {
  id: string;
  Email: string;
  First_Name: string;
  Full_Name: string;
  Last_Name: string;
}

export interface ZohoMeetingRecord {
  id: string;
  Who_Id: {
    name: string;
    id: string;
  };
}

export interface Approval {
  delegate?: boolean;
  approve: boolean;
  reject: boolean;
  resubmit: boolean;
}

export interface ZohoModule {
  module?: string;
  name?: string;
  id: string;
}

export interface CreatedBy {
  name: string;
  id: string;
  email: string;
}

export interface RelatedList {
  display_label: string;
  visible: boolean;
  api_name: string;
  module: Module;
  name: string;
  id: string;
  href: string;
  type: string;
}

export interface Module {
  api_name: string;
  id: string;
}

export interface ZohoCUDReturn {
  code: string;
  details: {
    Modified_Time?: Date;
    Modified_By?: ZohoModule;
    Created_Time?: Date;
    id: string;
    Created_By?: ZohoModule;
  };
  message: string;
  status: string;
}

export interface Customer {
  id: string;
  lastUpdateByAppUserId: null;
  lastUpdateByZohoUserId: null;
  lastUpdateSource: null;
  createdAt: Date;
  updatedAt: Date;
  status: string;
  lastLogin: null;
  dateInvited: Date;
}

export interface ZohoCurrentUser {
  created_time: string;
  Modified_Time: string;
  role: Layout;
  image_link: string;
  profile: Layout;
  last_name: string;
  microsoft: boolean;
  created_by: Layout;
  zuid: string;
  confirm: boolean;
  Modified_By: Layout;
  locale_code: string;
  full_name: string;
  territories: any[];
  Currency: string;
  alias: null;
  id: string;
  sandboxDeveloper: boolean;
  first_name: null;
  email: string;
  Reporting_To: null;
  status: string;
}

export type Apps =
  | "buffer"
  | "bufferAccessLink"
  | "esaReusable"
  | "esaSales"
  | "bmh"
  | "esa"
  | "bevac";

export type StandaloneApps =
  | "buffer"
  | "bufferAccessLink"
  | "bmh"
  | "bevac"
  | "esaSales";

export type AppIntegrationSources = "zoho" | "client-portal";

export type BaseURLTypes = "n8n" | "backend" | "crh" | "cal";

export type KeysTypes = "technicalAdminUsersKey";

export enum RouteServiceEnum {
  currentUser = "currentUser",
  records = "records",
}

export interface AppProperties {
  notForProfiles?: string[] | null;
  onlyForProfiles?: string[] | null;
  title: string;
  onlyForTabs?: {
    tabs: string | string[];
    errorMessage?: string | ((currentTab: string) => string);
  };
  services?: RouteServiceEnum | RouteServiceEnum[];
  entity?: boolean;
  appKey: string;
  data?: Record<string, any>;
  skipPageLoadData?: boolean;
  skipTwoWayMessaging?: boolean;
  page?: string;
}

export type AppConfig = Record<Apps, Record<string, AppProperties>>;
