import React, { ReactNode } from "react";
import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";

import store from "redux/store";
import { notifications } from "services";
import { ErrorCatchPage } from "components/ErrorCatchPage";
import theme from "./theme";

export interface RootProps {
  children: ReactNode;
}

const Root = (() => {
  let customizedStore: typeof store;

  return class RootWrapper extends React.PureComponent<RootProps> {
    static getStore() {
      return customizedStore;
    }

    render() {
      const { children } = this.props;

      customizedStore = store;

      return (
        <Provider store={customizedStore}>
          <SnackbarProvider
            ref={notifications.setRef}
            maxSnack={2}
            autoHideDuration={4000}
            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          >
            <ThemeProvider theme={theme}>
              <ErrorCatchPage>{children}</ErrorCatchPage>
            </ThemeProvider>
          </SnackbarProvider>
        </Provider>
      );
    }
  };
})();

export default Root;
