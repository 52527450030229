import { Box, Button, Typography } from "@mui/material";
import React, { useCallback, useEffect } from "react";

interface Props {
  message?: string | null;
  onButtonClick?: () => Promise<void>;
  buttonText?: string;
  title: string;
}

export const ErrorMessage: React.FC<Props> = ({
  message,
  onButtonClick,
  buttonText = "Retry",
  title,
}) => {
  const handleClick = useCallback(() => {
    if (onButtonClick) {
      onButtonClick();
    }
  }, [onButtonClick]);

  useEffect(() => {
    document.title = title;
  }, [title]);

  if (!message) {
    return null;
  }

  return (
    <Box py={4} px={3}>
      <Box
        p={3}
        mx="auto"
        border="1px solid"
        borderColor="error.main"
        borderRadius="8px"
        display="flex"
        flexDirection="column"
        alignItems="center"
        maxWidth="650px"
      >
        <Typography
          variant="h5"
          component="p"
          color="error"
          align="center"
          role="alert"
          paragraph
        >
          {message}
        </Typography>
        {!!onButtonClick && (
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={handleClick}
          >
            {buttonText}
          </Button>
        )}
      </Box>
    </Box>
  );
};
