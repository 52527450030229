import axios from "axios";
import { baseURLs } from "helpers";
import { Apps } from "types";

const client = axios.create();
const silentAxiosClient = axios.create();

export const setBaseURL = (app: Apps, fallbackApp?: Apps | null) => {
  const baseURL = fallbackApp
    ? baseURLs[fallbackApp]?.backend
    : baseURLs[app].backend;

  if (!baseURL) {
    throw new Error("Base url is not found");
  }

  client.defaults.baseURL = baseURL;
  silentAxiosClient.defaults.baseURL = baseURL;
};

export const setAuthorizationHeader = (token: string) => {
  client.defaults.headers.Authorization = `Bearer ${token}`;
  silentAxiosClient.defaults.headers.Authorization = `Bearer ${token}`;
};

export const removeAuthorizationHeader = () => {
  delete client.defaults.headers.Authorization;
  delete silentAxiosClient.defaults.headers.Authorization;
};

export const apiClient = client;
export const silentClient = silentAxiosClient;
