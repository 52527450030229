export const esaSalesAppKeys = {
  intakeFormObjectivesStep: {
    appKey: "PJnQNS8X7L",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Intake form - Your Objectives step",
    entity: false,
  },
  intakeFormAnything: {
    appKey: "6LUZKLF6Ai",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Intake form - Anything",
    entity: false,
  },
  customerAcquisition: {
    appKey: "lvVKfMP1jy",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Your Customer Acquisition",
    entity: false,
  },
  customerRetention: {
    appKey: "SDB1vtez0C",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Your Customer Retention",
    entity: false,
  },
  organizationalStructure: {
    appKey: "57ai99AIxe",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Your Organizational Structure",
    entity: false,
  },
  confirmInterestLead: {
    appKey: "jzgLvgv6iJ",
    title: "Confirm Interest - Lead",
    data: {
      zohoConfig: {
        widget: {
          dimensions: {
            width: 1200,
            height: 700,
          },
        },
      },
    },
  },
  masterClass: {
    appKey: "6dyyXmgH3K",
    title: "Master Class",
  },
  businessOverview: {
    // appKey: "HmrBZ1GCZz",
    appKey: "LGliQ2VOUB",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Your Business Overview",
    entity: false,
  },
  financialSituation: {
    // appKey: "oOYSocJtlc",
    appKey: "A93S1M3caw",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Your Current Financial Situation",
    entity: false,
  },
  productAndServices: {
    appKey: "lDMRWIxaG3",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Your Products and Services",
    entity: false,
  },
  signup: {
    appKey: "sw8evrb1MK",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Signup To Consulting session",
    entity: false,
  },
  consultingReport: {
    appKey: "TDoG5M55XW",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Consulting Report",
    entity: false,
  },
  uploadConsultingReport: {
    appKey: "YSEVxPXUZk",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Upload Consulting Report",
    entity: false,
    data: {
      zohoConfig: {
        widget: {
          dimensions: {
            width: 700,
            height: 300,
          },
        },
      },
    },
  },
  systemsAndProcessesStep: {
    appKey: "nG9GGjj0dU",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Systems and Processes step",
    entity: false,
  },
  privateConsulting: {
    appKey: "brR3TGrnXr",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Private Consulting",
    entity: false,
  },
  privateConsultingMobile: {
    appKey: "hr6roFFrCj",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Private Consulting Mobile",
    entity: false,
  },
  firstPrivateConsulting: {
    appKey: "pydBQjuqYO",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "First Private Consulting",
    entity: false,
  },
  consultingMaterial: {
    appKey: "KTskCsKyAI",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Client Portal Consulting Material Tab",
    entity: false,
  },
  bookConsultingSession: {
    appKey: "jFmXlQa4Mi",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Book consulting session",
    entity: false,
    data: {
      zohoConfig: {
        widget: {
          dimensions: {
            width: 900,
            height: 620,
          },
        },
      },
    },
  },
  bookConsultingSessionNextSteps: {
    appKey: "3n2Fy8bH94",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Book consulting session next steps",
    entity: false,
    data: {
      zohoConfig: {
        widget: {
          dimensions: {
            width: 900,
            height: 560,
          },
        },
      },
    },
  },
  consultingMaterials: {
    appKey: "t1l88bNE2a",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Consulting Materials",
    entity: false,
  },
  masterClassVideos: {
    appKey: "6fqOYY5KeP",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Masterclass Videos",
    entity: false,
  },
  masterClassVideosMobile: {
    appKey: "LTTAmlQXt0",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Masterclass Videos Mobile",
    entity: false,
  },
  paySignUpLink: {
    appKey: "HABgquRvcR",
    title: "Pay | Send Signup Link",
    data: {
      zohoConfig: {
        widget: {
          dimensions: {
            width: 1200,
            height: 700,
          },
        },
      },
    },
  },
  paymentRequired: {
    appKey: "OoFZCMgGD9",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Payment Required",
    entity: false,
  },
};
