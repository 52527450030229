export const bmhAppKeys = {
  recordAccessLogs: {
    appKey: "utEXvFZD1W",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Record Access Logs",
    entity: true,
  },
  recordAccessLogsSettings: {
    appKey: "utEXvFZD1W",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Record Access Logs",
    entity: false,
  },
  cancelTreatmentWidget: {
    appKey: "19QcSMb0bp",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Cancel Treatment Widget",
    entity: false,
    data: {
      zohoConfig: {
        widget: {
          dimensions: {
            width: 700,
            height: 600,
          },
        },
      },
    },
  },
  renewTreatmentWidget: {
    appKey: "Yf2RYVFVhd",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Renew Treatment Widget",
    entity: false,
    data: {
      zohoConfig: {
        widget: {
          dimensions: {
            width: 700,
            height: 250,
          },
        },
      },
    },
  },
  selectContractForClientWidget: {
    appKey: "iyQrZZmIhF",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Select contract for client widget",
    entity: false,
    data: {
      zohoConfig: {
        widget: {
          dimensions: {
            width: 700,
            height: 600,
          },
        },
      },
    },
  },
  confirmMedicationDisposalWidget: {
    appKey: "M05TGnf8rf",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Confirm Medication Disposal Widget",
    entity: false,
    data: {
      zohoConfig: {
        widget: {
          dimensions: {
            width: 1000,
            height: 600,
          },
        },
      },
    },
  },

  scanProductWidget: {
    appKey: "A7YVE58LRH",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Scan Product Widget",
    entity: false,
    data: {
      zohoConfig: {
        widget: {
          dimensions: {
            width: 700,
            height: 300,
          },
        },
      },
    },
  },

  signPrescriptionWidget: {
    appKey: "bN4MvOhUuA",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Sign Prescription And CTTP Widget",
    entity: false,
    data: {
      zohoConfig: {
        widget: {
          dimensions: {
            width: 1000,
            height: 700,
          },
        },
      },
    },
  },

  confirmOrderPackingWidget: {
    appKey: "xzBX9aSS8b",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Confirm Order Packing Widget",
    entity: false,
    // data: {
    //   zohoConfig: {
    //     widget: {
    //       dimensions: {
    //         width: 800,
    //         height: 600,
    //       },
    //     },
    //   },
    // },
  },

  bulkConfirmOrdersPackingWidget: {
    appKey: "KyxhsJIMXX",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Bulk Confirm Order Packing Widget",
    entity: false,
    data: {
      zohoConfig: {
        widget: {
          dimensions: {
            width: 1000,
            height: 600,
          },
        },
      },
    },
  },

  ordersDetailsWidget: {
    appKey: "OsGcuAIPgf",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Complete Order Details",
    entity: false,
    data: {
      zohoConfig: {
        widget: {
          dimensions: {
            width: 1200,
            height: 700,
          },
        },
      },
    },
  },

  bulkMarkPendingManifestingWidget: {
    appKey: "oHXAqLENkL",
    notForProfiles: null,
    onlyForProfiles: null,
    title:
      "Bulk Mark Pending Manifesting Orders As Manifested Blueprint Widget",
    entity: false,
    data: {
      zohoConfig: {
        widget: {
          dimensions: {
            width: 1000,
            height: 600,
          },
        },
      },
    },
  },

  manageClientCheckupWidget: {
    appKey: "mfgmVtb1Wo",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Manage Client Checkup Widget",
    entity: false,
    data: {
      zohoConfig: {
        widget: {
          dimensions: {
            width: 700,
            height: 500,
          },
        },
      },
    },
  },

  resumeTreatmentWidget: {
    appKey: "x1WgCttGtO",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Resume Treatment Widget",
    entity: false,
    data: {
      zohoConfig: {
        widget: {
          dimensions: {
            width: 700,
            height: 350,
          },
        },
      },
    },
  },

  createTreatmentPlanWidget: {
    appKey: "xDkAtItXnf",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Create Treatment Plan Widget",
    entity: false,
    data: {
      zohoConfig: {
        widget: {
          dimensions: {
            width: 1000,
            height: 700,
          },
        },
      },
    },
  },

  completeTreatmentPlanWidget: {
    appKey: "hfGhNO1rDc",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Complete Treatment Plan Details Widget",
    entity: false,
    data: {
      zohoConfig: {
        widget: {
          dimensions: {
            width: 1000,
            height: 700,
          },
        },
      },
    },
  },

  pauseTreatmentBlueprintTransition: {
    appKey: "LZ3Mcs9q8J",
    title: "Pause Treatment Blueprint Transition",
    data: {
      zohoConfig: {
        widget: {
          dimensions: {
            width: 900,
            height: 550,
          },
        },
      },
    },
  },
  checkoutPageManagementWidget: {
    appKey: "vwPFKYVmNN",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Checkout Page Management Widget",
    entity: false,
  },
  createReplacementOrder: {
    appKey: "BNBWlKirES",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Create Replacement Order",
    entity: false,
    data: {
      zohoConfig: {
        widget: {
          dimensions: {
            width: 900,
            height: 700,
          },
        },
      },
    },
  },
  prescriptionProvideClarification: {
    appKey: "7JUjSuMnZw",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Prescription Provide Clarification",
    entity: false,
    data: {
      zohoConfig: {
        widget: {
          dimensions: {
            width: 900,
            height: 700,
          },
        },
      },
    },
  },
  prescriptionRequestClarification: {
    appKey: "v6oItQCVo7",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Prescription Request Clarification",
    entity: false,
    data: {
      zohoConfig: {
        widget: {
          dimensions: {
            width: 900,
            height: 500,
          },
        },
      },
    },
  },
  cancelTreatmentOrder: {
    appKey: "6yfyc68FiU",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Cancel treatment order",
    entity: false,
    data: {
      zohoConfig: {
        widget: {
          dimensions: {
            width: 800,
            height: 550,
          },
        },
      },
    },
  },
  selectContractForClient: {
    appKey: "iyQrZZmIhF",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Select contract for client widget",
    entity: false,
    data: {
      zohoConfig: {
        widget: {
          dimensions: {
            width: 600,
            height: 400,
          },
        },
      },
    },
  },
  treatmentDetails: {
    appKey: "xluQM95RQN",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Treatment details",
    entity: false,
  },
  treatmentDetailsMobile: {
    appKey: "SgJkUPrDh4",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Treatment details",
    entity: false,
  },
  treatmentsList: {
    appKey: "iT54NZJrUX",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Treatments",
    entity: false,
  },
  treatmentsDetailsPage: {
    appKey: "iT54NZJrUX",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Treatments",
    entity: false,
    page: "details",
  },
  treatmentsSignCTTP: {
    appKey: "6jgJcW1VYK",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Sign CCTP",
    entity: false,
  },
  treatmentsPayment: {
    appKey: "6jgJcW1VYK",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Sign CCTP",
    entity: false,
    page: "payment",
  },
  startRenewal: {
    appKey: "jTPSQAEODo",
    title: "Start Prescription Renewal",
    data: {
      zohoConfig: {
        widget: {
          dimensions: {
            width: 700,
            height: 580,
          },
        },
      },
    },
  },
  validateOrder: {
    appKey: "xGoIOLZVuh",
    title: "Validate Order Widget",
    data: {
      zohoConfig: {
        widget: {
          dimensions: {
            width: 1200,
            height: 780,
          },
        },
      },
    },
  },
  dispenseOrder: {
    appKey: "968rufl1Pt",
    title: "Dispense Order Widget",
    data: {
      zohoConfig: {
        widget: {
          dimensions: {
            width: 1200,
            height: 780,
          },
        },
      },
    },
  },
  updateTreatmentUpcomingOrders: {
    appKey: "XN3Sd1LMvN",
    title: "Update treatment upcoming orders",
    data: {
      zohoConfig: {
        widget: {
          dimensions: {
            width: 1200,
            height: 600,
          },
        },
      },
    },
  },
  performFinalCheck: {
    appKey: "wtoKj2QW5p",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Perform final check",
    entity: false,
    data: {
      zohoConfig: {
        widget: {
          dimensions: {
            width: 1200,
            height: 700,
          },
        },
      },
    },
  },
  updateTreatmentPlan: {
    appKey: "odtEJ1pgNP",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Update Treatment Plan",
    entity: false,
    data: {
      zohoConfig: {
        widget: {
          dimensions: {
            width: 1200,
            height: 700,
          },
        },
      },
    },
  },
  reviewAndFinalizeTreatment: {
    appKey: "dwDQuSmJZa",
    title: "Review and Finalize Treatment",
    data: {
      zohoConfig: {
        widget: {
          dimensions: {
            width: 1200,
            height: 700,
          },
        },
      },
    },
  },
  migrateTreatment: {
    appKey: "egA9cohEkB",
    title: "Migrate Treatment",
    data: {
      zohoConfig: {
        widget: {
          dimensions: {
            width: 1200,
            height: 700,
          },
        },
      },
    },
  },
};
