export const bevacAppKeys = {
  recordPayment: {
    appKey: "7uqbHxrOA4",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Record Payment",
    entity: false,
    data: {
      zohoConfig: {
        widget: {
          dimensions: {
            width: 700,
            height: 470,
          },
        },
      },
    },
  },
  callRecordings: {
    appKey: "W4neUfR8yp",
    notForProfiles: null,
    onlyForProfiles: null,
    title: "Call Recordings",
    entity: false,
    data: {
      zohoConfig: {
        widget: {
          dimensions: {
            width: 1200,
            height: 600,
          },
        },
      },
    },
  },
};
