import { ErrorMessage } from "components/ErrorMessage";
import React, { useMemo } from "react";

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  iframe: {
    width: "100%",
    height: "calc(100vh - 20px)",
    border: 0,
    outline: 0,
  },
}));

interface PageLoadData {
  ids?: string[] | null;
  id?: string | null;
  entity?: Record<string, any> | null;
  records?: Record<string, Record<string, any>> | null;
  record?: Record<string, any> | null;
  token?: string | null;
}

interface Props {
  url: string;
  title: string;
  pageLoadData: PageLoadData;
  skipPageLoadData?: boolean;
  handleIframeLoaded: () => void;
  iframeID: string;
}

export const IframeComponent = ({
  url,
  title,
  pageLoadData: { token },
  pageLoadData,
  skipPageLoadData,
  handleIframeLoaded,
  iframeID,
}: Props) => {
  const { classes } = useStyles();

  const src = useMemo(() => {
    return `${url}?page_load_data=${encodeURIComponent(
      JSON.stringify(pageLoadData)
    )}&uib_skip_init_loader`;
  }, [url, pageLoadData]);

  if (!skipPageLoadData && !token) {
    return <ErrorMessage message="Could not get required data" title={title} />;
  }

  return (
    <iframe
      id={iframeID}
      className={classes.iframe}
      src={src}
      title={title}
      onLoad={handleIframeLoaded}
    />
  );
};
