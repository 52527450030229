import { Apps, KeysTypes } from "types";

export const keys: Record<Apps, Record<KeysTypes, string>> = {
  esa: {
    technicalAdminUsersKey: "",
  },

  esaSales: {
    technicalAdminUsersKey: "",
  },

  buffer: {
    technicalAdminUsersKey:
      process.env.REACT_APP_BUFFER_TECHNICAL_ADMIN_USERS_KEY || "",
  },

  bufferAccessLink: {
    technicalAdminUsersKey: "",
  },

  bmh: {
    technicalAdminUsersKey:
      process.env.REACT_APP_BMH_TECHNICAL_ADMIN_USERS_KEY || "",
  },

  bevac: {
    technicalAdminUsersKey: "",
  },

  esaReusable: {
    technicalAdminUsersKey: "",
  },
};
