import React, { useEffect, useCallback, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { AppIntegrationSources, Apps, StandaloneApps } from "types";
import { APP_TITLE } from "configs";
import { ErrorMessage } from "components/ErrorMessage";
import { ZohoIntegration } from "components/ZohoIntegration";
import { ClientPortalIntegration } from "components/ClientPortalIntegration";
import { setBaseURL } from "apis";
import { getAppConfig } from "./helpers";

interface Props {
  init: boolean;
  path: Apps;
}

export const Integration = ({ path, init }: Props) => {
  const [error, setError] = useState("");

  const [searchParams] = useSearchParams();
  const {
    appKey,
    fallbackApp,
    integrationSource,
    environment,
    extraData,
    technicalConfigRestriction,
  } = useMemo(() => {
    return {
      appKey: searchParams.get("appKey") || "",
      environment: searchParams.get("environment"),
      integrationSource: (searchParams.get("appIntegrationSource") ||
        "zoho") as AppIntegrationSources,
      extraData: searchParams.get("extraData"),
      fallbackApp: searchParams.get("fallbackApp") as StandaloneApps | null,
      technicalConfigRestriction:
        (searchParams.get("technicalConfigRestriction") as string) === "true",
    };
  }, [searchParams]);

  const { url, title, ...rest } = useMemo(() => {
    try {
      return getAppConfig({ path, appKey, environment, fallbackApp });
    } catch (e) {
      const err = e as Error;
      setError(err.message);
    }
    return {} as ReturnType<typeof getAppConfig>;
  }, [path, appKey, environment, fallbackApp]);

  const handleBaseURL = useCallback(() => {
    try {
      setBaseURL(path, fallbackApp);
    } catch (e) {
      const err = e as Error;
      setError(err.message);
    }
  }, [fallbackApp, path]);

  useEffect(() => {
    handleBaseURL();
  }, [handleBaseURL]);

  useEffect(() => {
    document.title = title || APP_TITLE;
  }, [title]);

  if (error) {
    return <ErrorMessage message={error} title={title} />;
  }

  if (!appKey || !path) {
    return <ErrorMessage message="404 | Page not found" title={title} />;
  }

  if (!url) {
    return <ErrorMessage message="Invalid app key provided" title={title} />;
  }

  if (integrationSource === "zoho") {
    return (
      <ZohoIntegration
        init={init}
        url={url}
        title={title}
        path={path}
        fallbackApp={fallbackApp}
        extraData={extraData}
        technicalConfigRestriction={technicalConfigRestriction}
        {...rest}
      />
    );
  }

  if (integrationSource === "client-portal") {
    return (
      <ClientPortalIntegration
        title={title}
        url={url}
        path={path}
        extraData={extraData}
        fallbackApp={fallbackApp}
        {...rest}
      />
    );
  }

  return (
    <ErrorMessage message="Integration source is not supported" title={title} />
  );
};
