import { createTheme } from "@mui/material/styles";

export const TRANSITION = "0.2s";

export default createTheme({
  typography: {
    fontFamily: "Roboto",
    h1: {
      fontWeight: 700,
      fontSize: 40,
      fontFamily: "'Raleway', sans-serif",
    },
    h2: {
      fontWeight: 500,
      fontSize: 36,
      fontFamily: "'Raleway', sans-serif",
    },
    h3: {
      fontWeight: 700,
      fontSize: 32,
      fontFamily: "'Raleway', sans-serif",
    },
    h4: {
      fontWeight: 600,
      fontSize: 24,
      fontFamily: "'Raleway', sans-serif",
    },
    h5: {
      fontWeight: 500,
      fontSize: 20,
      fontFamily: "'Raleway', sans-serif",
    },
    h6: {
      fontWeight: 500,
      fontSize: 14,
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: 18,
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: 16,
      letterSpacing: 0.15,
    },
    body1: {
      fontWeight: 400,
      fontSize: 16,
    },
    body2: {
      fontWeight: 400,
      fontSize: 14,
    },
    button: {
      fontWeight: 500,
      fontSize: 14,
    },
    caption: {
      fontWeight: 400,
      fontSize: 12,
    },
  },
  palette: {
    primary: {
      main: "#1D3961",
      dark: "#011837",
      light: "#4C6890",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#0BBEE3",
      dark: "#0094B1",
      light: "#67E6FF",
      contrastText: "#1A1414",
    },
    info: {
      main: "#2196F3",
      dark: "#0B79D0",
      light: "#64B6F7",
    },
    success: {
      main: "#4CAF50",
      dark: "#3B873E",
      light: "#7BC67E",
      contrastText: "#fff",
    },
    error: {
      main: "#F44336",
      dark: "#E31B0C",
      light: "#F88078",
    },
    warning: {
      main: "#FF9800",
      dark: "#C77700",
      light: "#FFB547",
    },
  },
  // props: {
  //   MuiGrid: {
  //     spacing: 2,
  //   },
  //   MuiAppBar: {
  //     elevation: 0,
  //   },
  //   MuiSelect: {
  //     variant: "outlined",
  //     fullWidth: true,
  //   },
  //   MuiTextField: {
  //     fullWidth: true,
  //     variant: "outlined",
  //   },
  //   MuiButton: {
  //     variant: "contained",
  //     disableElevation: true,
  //   },
  // },
  spacing: 8,
});
