function appendScript(scriptToAppend: string) {
  return new Promise((resolve, reject) => {
    try {
      const script = document.createElement("script");
      script.src = scriptToAppend;
      script.lang = "javascript";
      script.type = "text/javascript";
      document.head.appendChild(script);
      script.onload = (e) => resolve(e);
    } catch (e) {
      reject(e);
    }
  });
}

function removeScript(scriptToRemove: string) {
  const tags = document.getElementsByTagName("script");
  // eslint-disable-next-line no-plusplus
  for (let i = tags.length; i >= 0; i--) {
    if (
      tags[i] &&
      tags[i].getAttribute("src") !== null &&
      tags[i]?.getAttribute("src")?.indexOf(`${scriptToRemove}`) !== -1
    ) {
      tags[i]?.parentNode?.removeChild(tags[i]);
    }
  }
}

function removeIframe(frameId: string) {
  const frame = document.getElementById(frameId);
  frame?.parentNode?.removeChild(frame);
}

export const loadZohoScript = {
  append: appendScript,
  remove: removeScript,
  removeIframe,
};
