import { Apps, BaseURLTypes } from "types";

export const baseURLs: Record<Apps, Record<BaseURLTypes, string>> = {
  esa: {
    n8n: "",
    crh: "",
    backend: "",
    cal: "",
  },

  buffer: {
    n8n: process.env.REACT_APP_BUFFER_N8N_BASE_URL || "",
    crh: process.env.REACT_APP_BUFFER_CRH_BASE_URL || "",
    backend: process.env.REACT_APP_BUFFER_BACKEND_BASE_URL || "",
    cal: "",
  },

  bufferAccessLink: {
    n8n: process.env.REACT_APP_BUFFER_N8N_BASE_URL || "",
    crh: process.env.REACT_APP_BUFFER_CRH_BASE_URL || "",
    backend: process.env.REACT_APP_BUFFER_BACKEND_BASE_URL || "",
    cal: "",
  },

  bmh: {
    n8n: process.env.REACT_APP_BMH_N8N_BASE_URL || "",
    crh: process.env.REACT_APP_BMH_CRH_BASE_URL || "",
    backend: process.env.REACT_APP_BMH_BACKEND_BASE_URL || "",
    cal: "",
  },

  esaSales: {
    n8n: process.env.REACT_APP_ESA_SALES_N8N_BASE_URL || "",
    crh: process.env.REACT_APP_ESA_SALES_CRH_BASE_URL || "",
    backend: process.env.REACT_APP_ESA_SALES_BACKEND_BASE_URL || "",
    cal: process.env.REACT_APP_ESA_SALES_CAL_BASE_URL || "",
  },

  bevac: {
    n8n: process.env.REACT_APP_BEVAC_N8N_BASE_URL || "",
    crh: process.env.REACT_APP_BEVAC_CRH_BASE_URL || "",
    backend: process.env.REACT_APP_BEVAC_BACKEND_BASE_URL || "",
    cal: "",
  },

  esaReusable: {
    n8n: "",
    crh: "",
    backend: "",
    cal: "",
  },
};
