import React, { useEffect } from "react";
import { Container, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "90vh",
  },
}));

interface Props {
  message: string;
  title: string;
}

export const InfoMessage: React.FC<Props> = ({ message, title }) => {
  const { classes } = useStyles();

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <Container className={classes.root}>
      <Typography variant="h5" color="primary">
        {message}
      </Typography>
    </Container>
  );
};
