import React, { Component, ErrorInfo, ReactNode } from "react";

import { ErrorDisplay } from "./ErrorDisplay";

interface Props {
  children?: ReactNode;
}

interface State {
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

export class ErrorCatchPage extends Component<Props, State> {
  public state: State = {
    error: null,
    errorInfo: null,
  };

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { error, errorInfo: null };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    return { error, errorInfo };
  }

  public render() {
    const { error, errorInfo } = this.state;
    const { children } = this.props;
    if (error || errorInfo) {
      return <ErrorDisplay />;
    }

    return children;
  }
}
