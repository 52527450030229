import React, { ReactNode, useCallback } from "react";
import { SharedProps, ProviderContext } from "notistack";
import { makeStyles } from "tss-react/mui";
import { IconButton, Box } from "@mui/material";
import {
  CheckCircleOutline,
  ReportProblemOutlined,
  ErrorOutlineOutlined,
  InfoOutlined,
  Close,
} from "@mui/icons-material";

const getVariantData = (variant: SharedProps["variant"]) => {
  if (variant === "success") {
    return {
      color: "#4FB056",
      textColor: "#224925",
      Icon: CheckCircleOutline,
    };
  }
  if (variant === "warning") {
    return {
      color: "#FDA336",
      textColor: "#6F4918",
      Icon: ReportProblemOutlined,
    };
  }
  if (variant === "error") {
    return {
      color: "#F3554C",
      textColor: "#6B2926",
      Icon: ErrorOutlineOutlined,
    };
  }
  return {
    color: "#429FF1",
    textColor: "#1B4467",
    Icon: InfoOutlined,
  };
};

interface StyleProps {
  color: string;
  textColor: string;
}

const useStyle = makeStyles<StyleProps>()(
  ({ spacing: s, breakpoints: b }, { textColor, color }) => ({
    wrapper: {
      background: "white",
      padding: s(1),
      borderLeftWidth: 10,
      borderTopWidth: 1,
      borderBottomWidth: 1,
      borderRightWidth: 1,
      borderColor: color,
      borderStyle: "solid",
      borderRadius: 5,
      display: "flex",
      alignItems: "center",
      color: textColor,
      [b.down("sm")]: {
        marginLeft: s(1),
        maxWidth: "300px",
      },
    },
    messageWrapper: {
      flex: 1,
      wordWrap: "break-word",
      overflow: "hidden",
    },
    icon: {
      marginRight: s(1),
      color,
    },
    closeIconWrapper: {
      marginLeft: "auto",
    },
    closeIcon: {
      marginLeft: s(1),
      color: "#7A7A7A",
    },
  })
);

interface Props {
  variant: SharedProps["variant"];
  message: ReactNode;
  messageKey: number | string;
  notiRef: ProviderContext | null;
}

const NotificationDisplay = React.forwardRef<any, Props>(
  ({ variant, message, notiRef, messageKey }, ref) => {
    const { color, textColor, Icon } = getVariantData(variant);
    const { classes } = useStyle({ color, textColor });

    const onCloseClick = useCallback(() => {
      notiRef?.closeSnackbar(messageKey);
    }, [messageKey, notiRef]);

    return (
      <div ref={ref} className={classes.wrapper}>
        <Icon className={classes.icon} />
        <Box className={classes.messageWrapper}>{message}</Box>
        <Box className={classes.closeIconWrapper}>
          <IconButton className={classes.closeIcon} onClick={onCloseClick}>
            <Close />
          </IconButton>
        </Box>
      </div>
    );
  }
);

export default NotificationDisplay;
