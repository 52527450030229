import { Apps } from "types";

export const ROUTES: Record<Apps, string> = {
  buffer: "/buffer/*",
  bufferAccessLink: "/buffer-access-link/*",
  bmh: "/bmh/*",
  esa: "/esa/*",
  esaReusable: "/esa-reusable/*",
  bevac: "/bevac/*",
  esaSales: "/esaSales/*",
};
