import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ROUTES, APP_TITLE } from "configs";
import NetworkErrorDialog from "components/NetworkErrorDialog";
import { Integration } from "components/Integration";
import { ErrorMessage } from "components/ErrorMessage";

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <ErrorMessage message="404 | Page not found" title={APP_TITLE} />
          }
        />
        <Route
          path={ROUTES.buffer}
          element={<Integration path="buffer" init />}
        />
        <Route
          path={ROUTES.bufferAccessLink}
          element={<Integration path="bufferAccessLink" init />}
        />
        <Route path={ROUTES.esa} element={<Integration path="esa" init />} />
        <Route
          path={ROUTES.esaReusable}
          element={<Integration path="esaReusable" init />}
        />
        <Route path={ROUTES.bmh} element={<Integration path="bmh" init />} />
        <Route
          path={ROUTES.bevac}
          element={<Integration path="bevac" init />}
        />
        <Route
          path={ROUTES.esaSales}
          element={<Integration path="esaSales" init />}
        />
      </Routes>
      <NetworkErrorDialog />
    </Router>
  );
}

export default App;
