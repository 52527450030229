import {
  CRMTriggers,
  PortalAuthentication,
  PortalEnum,
  ZohoRequestOptions,
  ZohoUtilsFE,
  ZohoUtilsFEConfigs,
} from "@deep-consulting-solutions/zoho-utils-fe";
import { baseURLs } from "helpers";
import { Apps } from "types";

export const createZohoClient = (baseURL: string, appKey?: Apps) => {
  const configs: ZohoUtilsFEConfigs = {
    authenticationServerDomain: `${baseURL}/request`,
    crm: {
      apiUrl: `https://www.zohoapis.com/crm/v2`,
      apiUrlV2_1: `https://www.zohoapis.com/crm/v3`,
      apiUrlV3: `https://www.zohoapis.com/crm/v3`,
    },
  };

  if (appKey === "bmh") {
    configs.crm = {
      apiUrl: `https://www.zohoapis.eu/crm/v2`,
      apiUrlV2_1: `https://www.zohoapis.eu/crm/v3`,
      apiUrlV3: `https://www.zohoapis.eu/crm/v3`,
    };
  }
  return new ZohoUtilsFE(configs);
};

export const getZohoClient = (appKey: Apps) => {
  const baseURL = baseURLs[appKey].crh;

  let instance: ZohoUtilsFE;

  return () => {
    if (!instance) {
      try {
        instance = createZohoClient(baseURL, appKey);
      } catch {
        throw new Error("zohoClient lib initialization failed");
      }
    }

    return instance;
  };
};

export const getCRMAuthentication = (token: string): PortalAuthentication => ({
  token,
  portal: PortalEnum.CRM,
});

export const getClientPortalAuthentication = (
  token: string
): PortalAuthentication => ({
  token,
  portal: PortalEnum.CLIENT_PORTAL,
});

export const getZohoPostMessage = (
  appKey: Apps,
  authentication: PortalAuthentication
) => {
  const zohoClient = getZohoClient(appKey)();

  return async (methodsChain: string[], args: unknown[]) => {
    try {
      const res = await zohoClient.processMessages(
        authentication,
        methodsChain,
        args
      );

      return res;
    } catch (e) {
      const error = e as Error;
      throw new Error(error.message);

      return undefined;
    }
  };
};

export const getZohoUtilsMethods = (
  appKey: Apps,
  authentication: PortalAuthentication
) => {
  const zohoClient = getZohoClient(appKey)();
  const get = async (module: string, id?: string, params?: any) => {
    return zohoClient.crm().get(authentication, module, id, params);
  };

  const post = async (url: string, data: any, version = "v2") => {
    return zohoClient.crm().post(authentication, url, data, version);
  };

  const getRecords = async (module: string, page?: number) => {
    return zohoClient.crm().getRecords(authentication, module, page);
  };

  const createRecord = async (
    module: string,
    data: any,
    assignmentRuleName?: string
  ) => {
    return zohoClient
      .crm()
      .createRecord(authentication, module, data, assignmentRuleName);
  };

  const createRecordV3 = async (
    module: string,
    data: any,
    assignmentRuleName?: string
  ) => {
    return zohoClient
      .crm()
      .createRecordV3(authentication, module, data, assignmentRuleName);
  };

  const createRecordWithTrigger = async (
    module: string,
    data: any,
    trigger: CRMTriggers[]
  ) => {
    return zohoClient
      .crm()
      .createRecordWithTrigger(authentication, module, data, trigger);
  };

  const updateRecord = async (module: string, id: string, data: any) => {
    return zohoClient.crm().updateRecord(authentication, module, id, data);
  };

  const updateRecordWithTrigger = async (
    module: string,
    id: string,
    data: any,
    trigger: CRMTriggers[]
  ) => {
    return zohoClient
      .crm()
      .updateRecordWithTrigger(authentication, module, id, data, trigger);
  };

  const updateRecordV3 = async (module: string, id: string, data: any) => {
    return zohoClient.crm().updateRecordV3(authentication, module, id, data);
  };

  const deleteRecord = async (module: string, id: string) => {
    return zohoClient.crm().deleteRecord(authentication, module, id);
  };

  const getRecordByID = async (module: string, id: string) => {
    return zohoClient.crm().getRecordByID(authentication, module, id);
  };

  const getRecordByIDWithRelatedList = async (
    module: string,
    id: string,
    relatedListAPIName: string
  ) => {
    return zohoClient
      .crm()
      .getRecordByIDWithRelatedList(
        authentication,
        module,
        id,
        relatedListAPIName
      );
  };

  const searchRecord = async (module: string, searchCriteria: string) => {
    return zohoClient
      .crm()
      .searchRecord(authentication, module, searchCriteria);
  };

  const searchRecordByEmail = async (module: string, email: string) => {
    return zohoClient.crm().searchRecordByEmail(authentication, module, email);
  };

  const createNoteForRecord = async (
    module: string,
    recordId: string,
    data: { Note_Title?: string; Note_Content: string },
    accessToken?: string
  ) => {
    return zohoClient
      .crm()
      .createNoteForRecord(authentication, module, recordId, data, accessToken);
  };

  const getNotesForRecord = async (
    module: string,
    recordId: string,
    queryOptions: {
      page: number;
      perPage: number;
      sort?: { by: string; order: "asc" | "desc" };
      fields?: string[];
    },
    paginateResponse?: boolean
  ) => {
    return zohoClient
      .crm()
      .getNotesForRecord(
        authentication,
        module,
        recordId,
        queryOptions,
        paginateResponse
      );
  };

  const getModuleMetadata = async (module: string) => {
    return zohoClient.crm().getModuleMetadata(authentication, module);
  };

  const getLayoutMetadata = async (module: string, layoutID?: string) => {
    return zohoClient.crm().getLayoutMetadata(authentication, module, layoutID);
  };

  const updateMultipleRecords = async (
    module: string,
    data: { id: string; [key: string]: any }[]
  ) => {
    return zohoClient.crm().updateMultipleRecords(authentication, module, data);
  };

  const convertLead = async (id: string, data: any) => {
    return zohoClient.crm().convertLead(authentication, id, data);
  };

  const getRecordsByQuery = async (query: string) => {
    return zohoClient.crm().getRecordsByQuery(authentication, query);
  };

  const getCRMVariables = async () => {
    return zohoClient.crm().getCRMVariables(authentication);
  };

  const passRequestAsProxy = async (data: {
    method: ZohoRequestOptions["method"];
    url: string;
    data: any;
    params: { [key: string]: string };
  }) => {
    return zohoClient.crm().passRequestAsProxy(authentication, data);
  };

  return {
    deleteRecord,
    updateRecordV3,
    get,
    getRecordByID,
    post,
    getRecords,
    createRecord,
    createRecordV3,
    createRecordWithTrigger,
    updateRecord,
    updateRecordWithTrigger,
    searchRecord,
    getRecordByIDWithRelatedList,
    searchRecordByEmail,
    createNoteForRecord,
    getNotesForRecord,
    getModuleMetadata,
    getLayoutMetadata,
    updateMultipleRecords,
    convertLead,
    getRecordsByQuery,
    getCRMVariables,
    passRequestAsProxy,
  };
};

export const zohoMethodNames = [
  "get",
  "post",
  "getRecords",
  "createRecord",
  "createRecordV3",
  "createRecordWithTrigger",
  "updateRecord",
  "updateRecordWithTrigger",
  "updateRecordV3",
  "deleteRecord",
  "getRecordByID",
  "getRecordByIDWithRelatedList",
  "searchRecord",
  "searchRecordByEmail",
  "createNoteForRecord",
  "getNotesForRecord",
  "getModuleMetadata",
  "getLayoutMetadata",
  "updateMultipleRecords",
  "convertLead",
  "getRecordsByQuery",
  "getCRMVariables",
  "passRequestAsProxy",
];
